const API_URL = 'http://localhost:3000/';
// const API_URL = 'https://forthsoft.com';
// const SERVER_ENDPOINT = 'https://forthsoft.hordecall.net';
const SERVER_ENDPOINT = 'https://aggregatorapi.approot.ng';


export const API_ROUTES = {
    SIGN_IN:                `${SERVER_ENDPOINT}/api/login`,
    CHANGE_PASSWORD:        `${SERVER_ENDPOINT}/api/changePassword`,
    CHANGE_APIKEY:          `${SERVER_ENDPOINT}/api/changeApiKey`,
    WHITELISTIP:            `${SERVER_ENDPOINT}/api/whitelistIP`,
    IPREQUEST:              `${SERVER_ENDPOINT}/api/ip-request`,
    VIEW_WHITELIST:         `${SERVER_ENDPOINT}/api/viewIpList`,
    VIEW_REQUESTS:          `${SERVER_ENDPOINT}/api/view-ip-request`,
    DEL_WHITELIST:          `${SERVER_ENDPOINT}/api/deleteIP`,
    UPDATE_WHITELIST:       `${SERVER_ENDPOINT}/api/updateIpList`,
    VIEW_SERVICES:          `${SERVER_ENDPOINT}/api/services`,
    VIEW_PRICEPOINTS:       `${SERVER_ENDPOINT}/api/pricePoint`,
    GET_COUNTS:             `${SERVER_ENDPOINT}/api/subscribersCount`,
    SUBSCRIBERS:            `${SERVER_ENDPOINT}/api/subscribers`,
    UNSUBSCRIBERS:          `${SERVER_ENDPOINT}/api/unsubscribers`,
    SERVICE_CHANNELS:       `${SERVER_ENDPOINT}/api/serviceChannels`,
    CHARGING_SYNC:          `${SERVER_ENDPOINT}/api/chargingSync`,
    CHARGING_UNSYNC:        `${SERVER_ENDPOINT}/api/chargingUnsync`,
    ALL_COUNTS:             `${SERVER_ENDPOINT}/api/count`,
    SERVICE_COUNTS:         `${SERVER_ENDPOINT}/api/serviceCount`,
    TWO_FA_TOKEN:           `${SERVER_ENDPOINT}/api/two-FA-token`,
    TWO_FA_CHECK:           `${SERVER_ENDPOINT}/api/two-FA-check`,
    TWO_FA_TOGGLE:          `${SERVER_ENDPOINT}/api/two-FA-toggle`,
    TOTAL_REVENUE:          `${SERVER_ENDPOINT}/api/total-revenue`,
    DEMAND_SERVICES:        `${SERVER_ENDPOINT}/api/demand-services`,
    ON_DEMAND_SERVICES:     `${SERVER_ENDPOINT}/api/on-demand-services`,
    OPERATORS:              `${SERVER_ENDPOINT}/api/operator`,
    DISTINCT_SERVICES:      `${SERVER_ENDPOINT}/api/distinctService`,
    SERVICE_PRODUCTS:       `${SERVER_ENDPOINT}/api/serviceProducts`,
    SERVICE_REVENUE:        `${SERVER_ENDPOINT}/api/serviceRevenue`,
    
    


    TODAY_REVENUE:          `${SERVER_ENDPOINT}/api/total-revenue-today`,
    SYNCS:                  `${SERVER_ENDPOINT}/api/telco-partner-sync`,
    
    
    

    SERVER_ENDPOINT,
}